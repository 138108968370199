<template>
  <div class="pa-4">
    <v-row>

      <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9" >
        <div class="d-flex justify-space-between mb-4">
          <h3 class=" font-weight-light">Наші товари</h3>
<!--          <div style="width: 250px">-->
<!--              <v-text-field  append-icon="mdi-magnify"  v-model="searchProducts" hide-details dense    label="Пошук"></v-text-field>-->
<!--          </div>-->
        </div>
<!--        <div style="width: 250px">-->
<!--          <v-select label="Групові дії" hide-details dense></v-select>-->
<!--        </div>-->

        <v-expand-transition mode="out-in" >
          <v-sheet v-if="selectedProducts.length > 0"  height="50">
              <v-menu   offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small   color="deep-purple" text v-bind="attrs" v-on="on" >
                    Групові дії <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(menuItem, i) in editGroupActions" :key="i" @click="editButton(menuItem.action,item)">
                    <v-list-item-title class="font-weight-light">{{ menuItem.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </v-sheet>

        </v-expand-transition>



        <v-simple-table>
          <tbody>
          <tr v-for="(item,i) in products" :key="i">
            <td>
              <div class="d-flex ma-3">
              <v-avatar class="mr-3"
                        size="48"  >
                <v-img :src="item.image_small" ratio="1" />
              </v-avatar>
              <div>
                <h4>{{ item.name }} </h4>
                {{ item.shortAbout }}
              </div>
            </div>
            </td>
            <td>
              <b>{{ item.price }} {{ BUSINESS_CURRENCY }}</b>
            </td>
            <td>
              <div class="d-flex align-center justify-end mt-2">
                <div v-if="cart[item.id]" class="d-flex">
                  <v-btn class="mr-3" small  icon @click="removeFromCart(item)">
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                  <h3 class="mr-3">{{  cart[item.id].quantity }}</h3>
                  <v-btn small  icon @click="addToCart(item)">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-btn @click="addToCart(item)" v-else class="noCaps" small outlined >
                  Додати
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </v-simple-table>


      </v-col>
      <v-col v-if="categories.length > 0"
             cols="12" xs="12" sm="12" md="12" lg="3" xl="3"  class="">

        <div class="d-flex justify-space-between align-center">
          <h3 class=" font-weight-light">Категорії</h3>
        </div>

        <div class="mt-8 pt-3">
          <v-list dense>
            <v-list-item-group color="deep-purple" mandatory v-model="selectedCategory">
              <v-list-item  :value="null">
                <v-list-item-title>
                  <div class="d-flex justify-space-between align-center">
                    <h3 class="font-weight-light"> Всі товари </h3>
                    <v-chip color="deep-purple" small outlined dark > <span class="caption">{{  products.length  }}</span></v-chip>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-for="( category, i ) in categories" :key="i" :value="category.id">
                <v-list-item-title>
                  <div class="d-flex justify-space-between align-center">
                    <h3 class="font-weight-light">{{ category.name }} </h3>
                    <div v-if="!showEditCategory">
                      <v-chip v-if="getCategoryCount(category.id) > 0" color="deep-purple" small outlined dark > <span class="caption">{{ getCategoryCount(category.id) }}</span></v-chip>
                    </div>
                    <v-btn v-else icon small @click="editCategory(category)"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>



        </div>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
  name: "BusinessDashboardProducts",
  data (){
    return {
      loading : false,

      categories : [],

      showEditCategory : false,
      selectedCategory : '',

      selectedProducts : [],
      searchProducts : '',
      products_headers : [

        {text:'Назва',value:'name'},
        // {text:'Популярність',value:'rating'},
        {text:'Ціна',value:'price'},
        {text:'',value:'action'},

      ],

      editActions : [

        { label: 'Редагувати', action: 'edit'   },
        { label: 'Видалити',   action: 'delete' },

      ],

      editGroupActions : [

        { label: 'Видалити',   action: 'delete_group' },

      ],

      displayEditor : false,
      editorType : 'category',
      editorInput : false,
      editorRemove : false,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    ...mapState('templates', [ 'products']),
    ...mapState('cart', [ 'cart']),
    productsSelect() {
      let items = []

      this.products.forEach((product)=>{
        items.push({
          text: product.name,
          value: product.id
        })
      })

      return items
    },
    productsFiltered() {
      var products = this.products
      if (this.selectedCategory) {
        products = products.filter(el=> el.category_id === this.selectedCategory)
      }
      return products
    }
  },
  methods : {
    ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),

    getCategoryCount(category) {
      return this.products.filter(el=>el.category_id === category).length
    },

    addToCart(product){
      // this.$store.state.cart.cart = product
      let addProduct = this.products.find(el => el.id === product.id)
      this.ADD_CART_ITEM(addProduct)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      let addProduct = this.products.find(el => el.id === product.id)
      this.REMOVE_CART_ITEM(addProduct)
      this.notify(`${product.name} видалено із корзини`)
    },
    removeFromCartAll(product) {
      this.REMOVE_CART_ITEM_ALL(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    checkRentedFirstTime(product) {

      if ( !this.$store.state.auth.loggedIn ) {

        let quantity = 0;
        let parent = this.products.find( el=> el.id === product.id)
        if ( parent.rentedProduct ) {

          if ( this.cart[parent.rentedProduct.id] ) {
            quantity += this.cart[parent.rentedProduct.id].quantity
          }
          let warnIndex = this.showRentProductWarning.indexOf(product.id)

          if ( product.quantity > quantity ) {
            if ( warnIndex === -1) {
              this.showRentProductWarning.push(product.id)
            }
          } else {
            if ( warnIndex !== -1)  {
              this.showRentProductWarning.splice(warnIndex,1)
            }
          }
        }
      }
    },
    getRentedProductQuantity(product) {
      let quantity = product.quantity
      if ( this.cart[product.id] ) {
        quantity = quantity + this.cart[product.id].quantity
      }
      let parent = this.products.find( el=> el.id === product.id)
      if ( parent )  {
        if ( this.cart[parent.parentProduct] ) {
          quantity = quantity - this.cart[parent.parentProduct].quantity
        }
        let warnIndex = this.showRentProductWarning.indexOf(product.id)
        if ( quantity < 0 ) {
          if ( warnIndex === -1)  {
            this.showRentProductWarning.push(product.id)
          }
        } else {
          if ( warnIndex !== -1)  {
            this.showRentProductWarning.splice(warnIndex,1)
          }
        }


      }
      return quantity
    },

  },

}
</script>

<style scoped>

</style>